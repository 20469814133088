import React from "react"
import { Helmet } from "react-helmet"
import { titleCase } from "../utils/parsers"

const Seo = ({ subTitle = 'Industrial Design', description = null }) => {

    const TITLE = `Gabriele Faoro | ${titleCase(subTitle)}`
    const DEFAULT_DESCRIPTION = 'Gabriele Faoro is an industrial designer, but also a teacher, a writer, a drummer and a spare time artist. He lives his days trying to converge all these personalities in one single body, without fainting.'
    const URL = 'https://www.gaab.studio/'

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <link rel="canonical" href={URL} />

            <title>{TITLE}</title>
            <meta name="title" content={TITLE} />
            <meta name="description" content={description || DEFAULT_DESCRIPTION} />

            <meta property="og:type" content="website" />
            <meta property="og:url" content={URL} />
            <meta property="og:title" content={TITLE} />
            <meta property="og:description" content={description || DEFAULT_DESCRIPTION} />
            <meta property="og:image" content="http://www.gaab.studio/preview.png" />
            <meta property="og:image:secure_url" content="https://www.gaab.studio/preview.png" />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={URL} />
            <meta property="twitter:title" content={TITLE} />
            <meta property="twitter:description" content={description || DEFAULT_DESCRIPTION} />
            <meta property="twitter:image" content="http://www.gaab.studio/preview.png" />

        </Helmet>
    )
}

export default Seo;